<template>
  <div class="ed">
    <router-view>

    </router-view>
<!--    <el-row>-->
<!--      <el-col :span="16">-->
<!--        右侧变懒-->
<!--      </el-col>-->
<!--      <el-col :span="8">-->
<!--        左侧侧边栏-->
<!--      </el-col>-->
<!--    </el-row>-->
  </div>
</template>

<script>

export default {
  name: "index",

}

</script>

<style scoped>
.ed{
  min-height: calc(100vh - 60px);
  background-color: #fff;
  margin: 5px auto 0;
  width: 80%;
  padding: 10px;
}
</style>